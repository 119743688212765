
    @import "config.scss";
 
.bannerContainer {
  position: relative;
  height: 25rem;
  margin-top: 30px;

  @include for-desktop-up {
    height: 30rem;
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  z-index: 1;
  position: absolute;
  top: 60px;
  width: 100%;
  padding-inline: 20px;
}

.img {
  position: relative;
  height: 100%;

  img {
    object-fit: cover;
  }
}

@import url('https://fonts.cdnfonts.com/css/druk-wide-bold');

.off {
  font-family: 'Druk Wide Bold', sans-serif;
  -webkit-text-stroke: 2px var(--color-primary);
}

.desktop {
  display: flex;
  justify-content: space-evenly;
}

.benefits {
  margin-top: 40px;
  margin-bottom: 60px;
}
.installments {
  margin-top: 30px;
  margin-bottom: 50px;

  @include for-desktop-up {
    margin-top: 60px;
    margin-bottom: 100px;
  }
}

.installmentsSlide {
  display: flex;
  justify-content: center;
  width: 100vw;

  @include for-desktop-up {
    width: 48vw;
    width: fit-content;
  }
}
.installmentsTitle {
  text-align: center;
  font-weight: 600 !important;
  margin-top: 20px;

  @include for-desktop-up {
    font-size: 24px !important;
    margin-top: 45px;
  }
}

.content {
  display: flex;
  gap: 80px;
  align-items: center;
  flex-direction: column;
}
.contentTitle {
  text-align: center;
  font-weight: 600 !important;
  margin-bottom: 20px;

  @include for-desktop-up {
    font-size: 24px !important;
  }
}

.brands {
  display: flex;
  gap: 30px;
  margin-bottom: 40px;
  flex-direction: column;
  .brandsTitle {
    text-align: center;
    font-weight: 600 !important;
    padding-inline: 20px;

    @include for-desktop-up {
      font-size: 24px !important;
    }
  }
}
