
    @import "config.scss";
 
.title {
  color: var(--color-primary) !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  margin-bottom: 15px;
}
.text {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center;
  max-width: 250px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge {
  color: var(--color-primary);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include for-desktop-up {
    width: 60px;
    height: 60px;
  }
}

.container {
  display: flex;
  // align-items: center;
  width: 100vw;

  @include for-desktop-up {
    width: 48vw;
  }

  @include for-desktop-up {
    width: fit-content;
  }
}
