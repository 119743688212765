
    @import "config.scss";
 
.img {
  height: 216px;
  max-width: 304px;

  @include for-desktop-up {
    height: 330px;
    width: 250px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-inline: 20px;

  @include for-desktop-up {
    gap: 50px;
    flex-direction: row;
  }
}

.content {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.text {
  max-width: 438px;
}
.gap {
  width: 150px;
}
