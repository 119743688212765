
    @import "config.scss";
 
.time {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.number {
  font-size: 35px !important;
  font-weight: 700 !important;

  @include for-desktop-up {
    font-size: 80px !important;
  }
}
.lable {
  font-size: 12px !important;
  font-weight: 500 !important;

  @include for-desktop-up {
    font-size: 24px !important;
  }
}

.dots {
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  font-size: 1rem !important;
  @include for-desktop-up {
    font-size: 3rem !important;
  }
}
